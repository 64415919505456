<template>
    <div class="main-menu">
        <div>
            <div class="py-2">
                <router-link to="/" class="has-text-icon"><icon icon="fa-house" /></router-link>
            </div>
            <div class="py-2">
                <router-link to="/preview" class="has-text-icon"><icon icon="fa-edit" /></router-link>
            </div>
            <div class="py-2">
                <router-link to="/about" class="has-text-icon"><icon icon="fa-star" /></router-link>
            </div>
        </div>
        <div>

        </div>
    </div>
    <section class="main-content">
        <div class="container">
            <router-view/>
        </div>
    </section>
</template>
<style>
.main-menu {
    background:#2E323A;
    position: fixed;
    top:0;
    left:0;
    height:100vh;
    width:50px;
    z-index: 39;
}
.main-content {
    margin-left: 50px;
}
.has-text-icon {color:#FFFFFF55}
a.router-link-active {color:#FFF !important}
/* .main-menu a {color:#000 !important} */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
